<template>
  <div>
    <b-card v-if="tagItem">
      <div slot="header">
        <strong>{{ $t('tagItemSort') }}</strong>
      </div>
      <draggable
        :list="tagItems"
        class="row"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
      >
        <b-col
          sm="12"
          md="6"
          lg="2"
          v-for="tagItem in tagItems"
          :key="tagItem.value"
        >
          <b-card v-if="tagItem.tagItem" style="min-height: 200px; text-align: center; cursor: pointer">
            <div style="min-height: 40px;">
              {{ tagItem.tagItem.value }}
            </div>
            <div>
              <b-img :src="getImageUrl(tagItem.tagItem, 'normal')"/>
            </div>
          </b-card>
        </b-col>
      </draggable>
      <br/>
      <b-row>
        <b-col sm="12" md="6" lg="2">
          <b-button
            type="submit"
            block
            variant="primary"
            @click.prevent="submit"
          >{{ $t('save') }}</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {APIService} from "../../services/api";
  import i18n from "@/plugins/i18n";

  export default {
    name: 'TagItemSort',
    components: {
      draggable
    },
    props: ['id'],
    data: () => {
      return {
        tagItem: null,
        dragging: false,
        tagItems: [],
      }
    },
    methods: {
      async submit() {
        this.tagItems.map((tagItemData, i) => {
          tagItemData.orderKey = i + 1;
          if (tagItemData.tagItem) {
            tagItemData.tagItem = {
              id: tagItemData.tagItem.id
            }
          }
          return tagItemData;
        })
        this.tagItem.masterRelatedTagItems = this.tagItems;
        try {
          await APIService.put(`tag-item/sort/${this.tagItem.id}`, this.tagItem.masterRelatedTagItems);
          this.$eventBus.$emit('alert', {
            type: 'success',
            text: i18n.t('dataWasSuccessfullySaved'),
          });
          this.$router.back();
        } catch (e) {
          console.error(e);
          this.$eventBus.$emit('alert', {
            type: 'danger',
            text: i18n.t('errorOnDataSaving'),
          });
        }
      },
      getImageUrl(tagItem, suffix) {
        if (tagItem.images.length === 0) {
          return '';
        }
        const image = tagItem.images[0];
        const imageModificationData = image.modifications.filter(modificationData => modificationData.suffix === suffix);
        if (imageModificationData.length === 0) {
          return '';
        }
        return imageModificationData[0].fileUrl;
      },
    },
    async beforeMount() {
      try {
        this.tagItem = await APIService.get(`tag-item/${this.id}?join=masterRelatedTagItems&join=masterRelatedTagItems.tagItem&sort=masterRelatedTagItems.orderKey,ASC`);
        if (this.tagItem && this.tagItem.masterRelatedTagItems && this.tagItem.masterRelatedTagItems.length > 0) {
          const tagItems = this.tagItem.masterRelatedTagItems;
          for (const tagItem of tagItems) {
            if (tagItem.tagItem) {
              tagItem.tagItem.images = await APIService.get(`image/${tagItem.tagItem.id}/TagItem`);
            }
          }
          this.tagItems = tagItems;
          console.log('this.tagItems',this.tagItems);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>
